<template>
  <v-dialog v-model="show" persistent transition="dialog-top-transition" max-width="400">
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-card elevation="10" max-height="1000" max-width="400">
      <v-card-actions class="closeIcon"><v-icon @click="show = false" color="green">mdi-close</v-icon></v-card-actions>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-text-field
            @keydown.space.prevent
            v-model="login.email"
            color="#137547"
            outlined
            label="Email"
            placeholder="Unesite Vaš email"
            type="text"
            :rules="emailRules"
            required
          ></v-text-field>
          <v-text-field
            @keydown.enter="submitForm"
            v-model="login.password"
            color="#137547"
            outlined
            label="Lozinka"
            placeholder="Unesite lozinku"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
            style="margin-bottom"
            :rules="passwordRules"
            required
          ></v-text-field>
          <p>{{ errorMessage }}</p>
          <v-row justify="center" dense> <p class="forgottenPassword" @click="() => this.$router.push('/zaboravljena-lozinka')">Zaboravili ste lozinku?</p></v-row>
          <v-row justify="center" dense>
            <v-btn block elevation="2" color="#137547" @click="submitForm">
              <p style="margin: auto; color: #ffff">Prijavi se</p>
            </v-btn>
          </v-row>
        </v-form>
        <v-row justify="center" dense class="noAccountQuestion">
          <h4>Još uvek nemaš nalog?</h4>
        </v-row>
        <v-row justify="center" dense>
          <v-btn block outlined elevation="2" color="#137547" href="/registracija" style="margin-bottom: 2rem; padding: 3px"> Registruj se </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SignInDialog",
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      show2: false,
      loading: false,
      errorMessage: "",
      emailRules: [
        (v) => !!v || "Email je obavezan",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||
          "Unesite email u ispravnom formatu",
      ],
      passwordRules: [(v) => !!v || "Lozinka je obavezna"],
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let userForm = {
          userName: this.login.email,
          password: this.login.password,
        };
        await this.LogIn(userForm)
          .then(() => {
            this.loading = false;
            this.show = false;
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data.includes("Password entered incorectly 5 times")) {
              this.errorMessage = "Pogrešno ste uneli lozinku 5 puta. Pokušajte ponovo kasnije.";
            } else if (err.response.data.includes("Password entered incorectly.")) {
              this.errorMessage = "Pogrešna lozinka. Pokušajte ponovo.";
            } else if (err.response.data.includes("Failed to login. No user with username")) {
              this.errorMessage = "Ne postoji korisnik sa unetim korisničkim imenom.";
            } else {
              this.errorMessage = "Došlo je do greške. Pokušajte ponovo kasnije.";
            }
            setTimeout(() => (this.errorMessage = ""), 3000);
          });
      }
    },
    submitOnEnter() {},
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.closeIcon {
  display: flex;
  justify-content: flex-end;
}

.forgottenPassword {
  color: #137547;
}
.noAccountQuestion {
  border-top: 2px solid #137547;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}

p {
  color: red;
  text-align: center;
}
</style>
